import { BriefcaseIcon, ChevronRightIcon, CogIcon, CreditCardIcon, FolderOpenIcon, IdentificationIcon, MenuIcon, UserIcon } from '@heroicons/react/outline'
import React from 'react'
import AccountGroups from '../../../AccountGroups';
import Billings from './Forms/Billings';
import BusinessForm from './Forms/Business/Business';
import ProfileForm from './Forms/Profile';
import WithdrawalAccount from './Forms/Withdrawal';
import PluginManagement from './Forms/PluginManagement';

const Settings = () => {
    const [active, setActive ] = React.useState(1);


  return (
    <div className='w-full h-full flex flex-col lg:flex-row space-x-0 relative overflow-y-hidden'>
        <div className='left-0 w-full flex-row justify-between lg:w-1/5 flex lg:flex-col lg:justify-start items-center'>
            {/* <SubMenuItem icon={<BriefcaseIcon className='guide-icon h-full w-full font-medium' />} title="Business Settings" active={active} setActive={setActive} index={0} /> */}
            <SubMenuItem icon={<UserIcon className='guide-icon h-full w-full font-medium' />} title="Profile Settings" active={active} setActive={setActive} index={1} />
            <SubMenuItem icon={<IdentificationIcon className='guide-icon h-full w-full font-medium' />} title="Verify Account" active={active} setActive={setActive} index={2} />
            <SubMenuItem icon={<FolderOpenIcon className='guide-icon h-full w-full font-medium' />} title="Manage Plugins" active={active} setActive={setActive} index={3} />
            <SubMenuItem icon={<CogIcon className='guide-icon h-full w-full font-medium' />} title="Accounts" active={active} setActive={setActive} index={4} />
            <SubMenuItem icon={<CreditCardIcon className='guide-icon h-full w-full font-medium' />} title="Balance" active={active} setActive={setActive} index={5} />
        </div>
        <div className='w-[2px] bg-gray-200 max-h-full'></div>
        <div className='flex flex-1 overflow-y-auto scrollbar '>
            { active === 0 && <BusinessForm />}
            { active === 1 && <ProfileForm />}
            { active === 2 && <Billings/>}
            { active === 3 && <PluginManagement />}
            { active === 4 && <AccountGroups/>}
            { active === 5 && <WithdrawalAccount/>}
        </div>
    </div>
  )
}

export default Settings

const SubMenuItem = (props) => {
    const {icon, title, active, index, setActive} = props;
    return (
        <div onClick={() => setActive(index)} className='flex cursor-pointer items-center justify-between w-full py-4 px-2 text-textcolor'>
            <div className='flex items-center justify-start space-x-2'>
                <span className={`ease_transition ${active === index && 'text-primary-500 lg:text-textcolor h-9 w-9'} h-6 w-6`}>{icon}</span>
                <span className={`${active === index && 'font-medium'} hidden lg:flex`}>{title}</span>
            </div>
            {active === index && <ChevronRightIcon className='guide-icon h-6 w-6 hidden lg:flex' />}
        </div>
    )
}